// This entry point is used to load all non-app global utilities and components
// that are shared across the entire site (e.g. admin, teacher/student
// dashboards).
//
// WARNING: This is NOT the place to put components that are used by the main
// application. Put those in the local_modules/ms-components and reference them
// from the relevant entry point.

import { createRoot } from 'react-dom/client';

import { ErrorBoundary } from 'ms-components/ErrorBoundary/ErrorBoundary';

import { DebugOverlay } from './DebugOverlay';

const reactRoot = document.createElement('div');
Object.assign(reactRoot.style, {
  // Put the div offscreen so it doesn't interfere with the main app.
  // Any components rendered here should be positioned absolutely or fixed.
  position: 'fixed',
  overflow: 'hidden',
  height: '0',
  width: '0',
  right: '0',
  top: '0',
});
document.body.appendChild(reactRoot);

const root = createRoot(reactRoot);
root.render(
  <ErrorBoundary name="NonAppGlobals" fallback={null}>
    <DebugOverlay />
  </ErrorBoundary>,
);

// Add any global event listeners here and other global setup below here.
