import { useEffect, useState } from 'react';

export function DebugOverlay() {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        e.ctrlKey &&
        e.altKey &&
        e.shiftKey &&
        e.code === 'KeyD' &&
        !e.repeat
      ) {
        e.preventDefault();
        // Wait for 2 seconds before enabling the debug overlay.
        const timeout = setTimeout(() => {
          setShow(true);
        }, 2000);
        window.addEventListener(
          'keyup',
          () => {
            clearTimeout(timeout);
          },
          { once: true },
        );
      }
    };

    window.addEventListener('keydown', handleKeyDown, { capture: true });
    return () => {
      window.removeEventListener('keydown', handleKeyDown, { capture: true });
    };
  }, []);

  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  // WARNING: This overlay is assumed to be visible to ANYONE. DO NOT put any
  // sensitive information here.
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  return show ? (
    <div
      style={{
        position: 'fixed',
        top: '20px',
        right: '20px',
        width: 'fit-content',
        maxWidth: '500px',
        height: 'fit-content',
        padding: '1em',
        background: 'rgba(0, 0, 0, 0.8)',
        borderRadius: '5px',
        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.4)',
        color: 'white',
        lineHeight: '1.5',
        fontFamily: 'monospace',
        fontSize: '90%',
        zIndex: 9999,
      }}
    >
      Debug Overlay — <button onClick={() => setShow(false)}>Close</button>
      <hr />
      JS build git sha: {process.env.BUILD_GIT_SHA} <br />
      JS build time: {process.env.BUILD_TIMESTAMP} <br />
    </div>
  ) : null;
}
